<template>
  <div class="roleInfo">
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />
      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top ? handLists.top.indexOf('add') != -1 : false"
      >
        添加管理员</a-button
      >
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        :from="addFromData"
      >
        <a-form-item label="登录账号">
          <a-input v-model="addFromData.user_login" />
        </a-form-item>
        <a-form-item label="昵称">
          <a-input v-model="addFromData.user_nickname" />
        </a-form-item>
        <a-form-item label="登录密码">
          <a-input
            v-model="addFromData.password"
            :placeholder="modalTitle == '新增管理员' ? '' : '不修改则不填写'"
          />
        </a-form-item>
        <a-form-item label="角色权限">
          <a-tree
            v-model="addFromData.ids"
            checkable
            defaultExpandAll
            :auto-expand-parent="autoExpandParent"
            :tree-data="addMenu"
            :replaceFields="{ key: 'id', title: 'name' }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="user_status" slot-scope="text">
        <a-tag :color="text == 1 ? 'green' : 'orange'">
          {{ text == 1 ? "启用" : "禁用" }}
        </a-tag>
      </span>

      <span slot="action" slot-scope="text, record">
        <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
          v-if="handLists.right?handLists.right.indexOf('edit') != -1:false"
        ></FontIcon>
        <a-divider
          type="vertical"
          v-if="
            handLists.right?handLists.right.indexOf('edit') != -1:false &&
              handLists.right?handLists.right.indexOf('setStatus') != -1:false
          "
        />
        <a-switch
          checked-children="启"
          un-checked-children="禁"
          :checked="record.user_status == 1"
          @click="confirm(record)"
          v-if="handLists.right?handLists.right.indexOf('setStatus') != -1:false"
        />
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  managerIndex,
  managerDataSave,
  managerSetStatus,
  roleIndex,
} from "@/apis/index";

import moment from "moment";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "用户名",
    dataIndex: "user_login",
    scopedSlots: { customRender: "user_login" },
  },
  {
    title: "昵称",
    dataIndex: "user_nickname",
    scopedSlots: { customRender: "user_nickname" },
  },
  {
    title: "文章",
    dataIndex: "article_count",
    scopedSlots: { customRender: "article_count" },
    customRender: (text, record) =>{

      return record.show_info.article_count ?? 0;
    }
  },
  {
    title: "视频",
    dataIndex: "video_count",
    scopedSlots: { customRender: "video_count" },
    customRender: (text, record) =>{

      return record.show_info.video_count ?? 0;
    }
  },
  {
    title: "病例",
    dataIndex: "case_count",
    scopedSlots: { customRender: "case_count" },
    customRender: (text, record) =>{

      return record.show_info.case_count ?? 0;
    }
  },

  {
    title: "最后登录时间",
    dataIndex: "last_login_time",
    scopedSlots: { customRender: "last_login_time" },
    customRender: (text, record) => {
      if (text != 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
  },
  {
    title: "最后登录IP",
    dataIndex: "last_login_ip",
    scopedSlots: { customRender: "last_login_ip" },
  },
  {
    title: "注册时间",
    dataIndex: "create_time",
    scopedSlots: { customRender: "create_time" },
    customRender: (text, record) => {
      return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "状态",
    dataIndex: "user_status",
    scopedSlots: { customRender: "user_status" },
    // customRender: (text, record) => {
    //   if (text == 1) {
    //     return "启用";
    //   } else {
    //     return "禁用";
    //   }
    // },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      addMenu: [],
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      count: 0,
      autoExpandParent: true,
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists = val;
    },
  },
  created() {
    this.getManagerIndex();
    this.getRoleIndex();
  },
  methods: {
    getRoleIndex() {
      roleIndex().then((res) => {
        this.addMenu = res.data.list;
      });
    },
    showModal() {
      this.modalTitle = "新增管理员";
      (this.addFromData = {
        id: "",
        user_login: "",
        user_nickname: "",
        password: "",
        ids: [],
      }),
        (this.visible = true);
    },
    handleOk(e) {
      this.confirmLoading = true;
      managerDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$message.success("成功");
          this.getManagerIndex();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "修改管理员信息";
      let ids = [];
      if (record.roles) {
        record.roles.forEach((item) => {
          ids.push(item.id);
        });
      }
      this.addFromData = {
        id: record.id,
        user_login: record.user_login,
        user_nickname: record.user_nickname,
        ids,
      };
      this.visible = true;
    },
    confirm(rec) {
      managerSetStatus({
        id: rec.id,
        status: rec.user_status == 1 ? 0 : 1,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("success");
          this.getManagerIndex();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getManagerIndex();
    },
    getManagerIndex() {
      managerIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getRoleIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getManagerIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
